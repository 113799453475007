/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import Provider from "../pages/provider"
import Notifier from "../common/notifier"
import TaskStatusViewer from "../common/tasks-status-viewer";

function Routes ({ history }) {
  const lastLocation = useLastLocation();

  const isAuthorized = useSelector((store) => !!store.auth.user);
  const menuConfig = useSelector((store) => store.builder.menuConfig);
  const taskViewerAnchor = useSelector((store) => store.builder.taskViewerAnchor);
  const userLastLocation = routerHelpers.getLastLocation();

  useEffect(() => {
    routerHelpers.saveLastLocation(lastLocation);
  }, [ lastLocation ]);

  return (
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        <Route path="/logout" component={LogoutPage} />
        <Route path="/provider/:request/:provider" component={Provider} />

        {!isAuthorized ? (
          /* Render auth page when user at `/auth` and not authorized. */
          <AuthPage />
        ) : (
          /* Otherwise redirect to root page (`/`) */
          <Redirect from="/auth" to={userLastLocation} />
        )}

        {!isAuthorized ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth/login" />
        ) : (
          <Layout>
            <HomePage />
          </Layout>
        )}
      </Switch>
      <Notifier />
      <TaskStatusViewer anchor={taskViewerAnchor} />
    </LayoutContextProvider>
  );
}

export default withRouter(Routes);
