import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Alert } from "reactstrap";
import clsx from "clsx";
import queryString from "query-string";
import { withSnackbar } from "notistack";

import Input from "../../common/Input";
import { login } from '../../store/modules/actions/auth.actions';
import { flagNames } from '../../store/modules/actions/auth.actions';
import { globalGA } from "../../../_metronic";
import { getFlags } from "../../store/modules/selectors/common.selector";

function Login(props) {
  const {
    intl,
    enqueueSnackbar
  } = props;

	const flags = useSelector(getFlags);

  useEffect(() => {
    const queryStringObj = queryString.parse(window.location.search);

    if (queryStringObj.error) {
      enqueueSnackbar(queryStringObj.error, { variant: "error" });
    }
  }, [
    enqueueSnackbar
  ]);

  const isLoading = flags.loading[flagNames.LOGIN] || flags.loading[flagNames.LOGGED_IN_USER];
  const error = flags.error[flagNames.LOGIN] || flags.error[flagNames.LOGGED_IN_USER];

  return (
    <React.Fragment>
      <div className="text-center mb-50">
        <h3>
          {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
      </div>

      <Formik
        initialValues={{
          email: "",
          password: ""
        }}
        validate={values => {
          const errors = {};

          if (!values.email) {
            // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
            errors.email = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_FIELD"
            });
          }

          if (!values.password) {
            errors.password = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          return errors;
        }}
        onSubmit={(values) => {
          props.login(values.email, values.password);
          globalGA("login", {
            eventAction: "success",
            eventCategory: "user"
          });
        }}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form
            noValidate={true}
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            {status && (
              <div role="alert" className="alert alert-danger">
                <div className="alert-text">{status}</div>
              </div>
            )}

            <Input
              containerClass="mb-25"
              labelId="AUTH.INPUT.EMAIL"
              variant="outlined"
              type="email"
              fullWidth
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              helperText={touched.email && errors.email}
              error={Boolean(touched.email && errors.email)}
            />

            <Input
              containerClass="mb-25"
              labelId="AUTH.INPUT.PASSWORD"
              variant="outlined"
              type="password"
              fullWidth
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              helperText={touched.password && errors.password}
              error={Boolean(touched.password && errors.password)}
            />

            {
              error && (
                <Alert color="danger" className="d-flex justify-content-center mb-25">
                  {error}
                </Alert>
              )
            }

            <div className="pt-20">
              <div className='mb-4 text-center'>
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={isLoading}
                  className={`btn btn-primary btn-lg btn-block btn-elevate kt-login__btn-primary py-3 ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
                    }
                  )}`}
                >
                  <FormattedMessage id="AUTH.LOGIN.BUTTON" />
                </button>
              </div>
              <div className='d-flex justify-content-center justify-content-xl-between flex-column flex-xl-row mb-4'>
                <div className="text-center">
                  <span className="kt-login__signup-label">
                    Don't have an account yet?
                  </span>
                  &nbsp;&nbsp;
                  <Link to="/auth/registration" className="kt-link kt-login__signup-link">
                    Sign Up!
                  </Link>
                </div>
                <div className="text-center">
                  <Link
                    to="/auth/forgot-password"
                    className="kt-link kt-login__link-forgot"
                  >
                    <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                  </Link>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default withSnackbar(
  injectIntl(
    connect(
      null,
      { login, }
    )(Login)
  )
);
