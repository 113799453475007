import React, { useCallback, useRef, useState, useEffect } from "react";
import {
	Formik
} from "formik";
import {
	useSelector,
	useDispatch
} from "react-redux";
import {
	FormattedMessage,
	useIntl
} from "react-intl";
import clsx from "clsx";
import {
	FormControlLabel,
	FormLabel,
	RadioGroup,
	Radio,
	makeStyles
} from "@material-ui/core";
import {
	Alert
} from "reactstrap";
import MuiPhoneNumber from "material-ui-phone-number";
import { useHistory, Prompt } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { parse } from "query-string";

import ImageIcon from './image-icon.png';
import Input from "../../../common/Input";
import useUserRestriction from "../../../common/hooks/user-restriction";
import IOSSwitch from '../../../common/IOSSwitch'	
import {
	updateProfile,
	flagNames,
	updatePassword,
	updateChatService,
	updateDownloadFormat,
	updateSettings,
} from "../../../store/modules/actions/auth.actions";
import { WarningBlocksIcon } from "../../../common/icons";
import Confirm from "../../../common/modals/confirm";
import { dataURLtoFile, doScrolling, sanitizeFile } from "../../../../_metronic";
import { setUpgradeDialog } from "../../../store/modules/actions/app.actions";
import CropModal from "../../../common/modals/crop-modal";
import { getFlags } from "../../../store/modules/selectors/common.selector";
import { getFileRenameSeparator, getUser } from "../../../store/modules/selectors/account.selector";

const useStyles = makeStyles((theme) => {
	return {
		logoContainer: {
			background: "rgba(224, 231, 255, 0.2)",
			borderRadius: "10px",
			height: "200px",
			maxHeight: "200px",

			"& img": {
				borderRadius: "10px",
			}
		},
		emptyLogoContainer: {
			border: `2px dashed ${theme.palette.extraColors.blueBorder}`,
		},
		emptyLogoMessage: {
			color: theme.palette.extraColors.grey,
			fontSize: "13px",
		},
		logoActionsContainer: {
			top: "10px",
			right: "10px"
		},
		logoAction: {
			cursor: "pointer",
			color: theme.palette.primary.main,
			background: "rgba(255, 255, 255, 0.49)",
			border: `1px solid ${theme.palette.extraColors.blueBorder}`,
			height: "24px",
			width: "24px",
			borderRadius: "12px"
		},
		logoPreview: {
			objectFit: "contain"
		},
		fieldSubtitle: {
			fontSize: 13,
			color: "#C3C9D5"
		},
		customCharacterInput: {
			maxWidth: 55,

			"& .MuiInputBase-input": {
				height: "22px !important",
				padding: "5px 16px",
				textAlign: "center",
			}
		}
	}
});

function Profile() {
	const dispatch = useDispatch();
	const classes = useStyles();
	const intl = useIntl();
	const history = useHistory();
	const CONTAINER_ID = "profile-container";
	const nextLocation = useRef(null);

	const flags = useSelector(getFlags);
	const user = useSelector(getUser);
	const fileRenameSeparator = useSelector(getFileRenameSeparator);
	
	const {
		goTo,
	} = parse(history.location.search);
	const [showCropModal, setCropModalStatus] = useState(false);
	const [restrictions, isInReadOnlyMode] = useUserRestriction();

	const [logoUrl, setLogoUrl] = useState('');
	const [logo, setLogo] = useState({
		value: '',
		error: '',
	});
	const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
	const [showUnsavedWarningModal, setUnsavedChangesWarningStatus] = useState(false);

	useEffect(() => {
		setLogoUrl(user.companyLogo);
	}, [ user ]);

	useEffect(() => {
		if (!goTo) return;

		const handle = setInterval(() => {
			const containerElement = document.getElementById(CONTAINER_ID);
			
			if (!containerElement) return;

			let scrollToElement = null;

			scrollToElement = document.getElementById(goTo);

			if (scrollToElement) {
				doScrolling(`#${goTo}`);
				clearInterval(handle);
			}
		}, 250);

		return () => clearInterval(handle);
	}, [goTo, history]);

	const onDrop = useCallback(async (acceptedFiles) => {
		const selectedFile = await sanitizeFile(acceptedFiles[0]);
		const type = selectedFile.name.split('.').pop();
		const isImageFile = [/png$/i, /jpg$/i, /jpeg$/i].some((imageType) => imageType.test(type));

		if (isInReadOnlyMode || !restrictions.features.BRANDED_INVITES || !restrictions.features.BRANDED_CLIENT_PORTAL) {
			dispatch(setUpgradeDialog("GENERAL.UPGRADE_MESSAGE"));
			return;
		}

		if (isImageFile) {
			setLogo({
				value: selectedFile,
				error: ''
			});
			setCropModalStatus(true);
		}
		else {
			setLogo({
				value: null,
				error: intl.formatMessage({ id: "CUSTOMIZATION.COMMUNICATION.BUSINESS_LOGO.INVALID_FILE" })
			});
		}
	}, [
		intl,
		restrictions,
		isInReadOnlyMode,
		dispatch,
	]);

	const hideCropModal = useCallback((croppedImageUrl) => {
		setLogoUrl(croppedImageUrl);
		setCropModalStatus(false);

		if (!hasUnsavedChanges) {
			setUnsavedChanges(true);
		}
	}, [hasUnsavedChanges]);

	const handleBlockedNavigation = useCallback((newLocation) => {
		if (hasUnsavedChanges) {
			nextLocation.current = newLocation;
			setUnsavedChangesWarningStatus(true);
			return false;
		}

		return true;
	}, [hasUnsavedChanges]);

	const updateCompanyProfile = useCallback(async (values) => {
		let file = null;

		if (logoUrl && logoUrl.startsWith("data")) {
			file = dataURLtoFile(logoUrl);
		}

		await dispatch(
			updateProfile(
				values.firstName,
				values.lastName,
				values.company,
				values.phone,
				file || logoUrl|| ""
			)
		);

		setUnsavedChanges(false);
	}, [dispatch, logoUrl]);

	const handleUnsavedModalClose = useCallback(async (shouldSave, values) => {
		if (shouldSave === null) {
			setUnsavedChangesWarningStatus(false);
		}
		else {
			if (shouldSave) {
				await updateCompanyProfile(values);
			}

			history.push(nextLocation.current.pathname);
		}
	}, [history, updateCompanyProfile]);

	const handleValidate = useCallback((values) => {
		const errors = {};

		setUnsavedChanges(true);

		if (!values.firstName) {
			errors.firstName = intl.formatMessage({
				id: "AUTH.VALIDATION.REQUIRED_FIELD"
			});
		}
		if (!values.lastName) {
			errors.lastName = intl.formatMessage({
				id: "AUTH.VALIDATION.REQUIRED_FIELD"
			});
		}

		return errors;
	}, [intl]);

	const handleFileRenameSeparatorChange = useCallback((fileRenameSeparator) => {
		if (flags.loading[flagNames.UPDATE_FILE_RENAME_SEPARATOR]) {
			return;
		}

		dispatch(
			updateSettings({
				fileRenameSeparator,
			}),
		);
	}, [dispatch, flags.loading]);

	// it's not set to nothing or space which has their own separate options in the UI
	const [isCustomFileRenameCharacterSelected, setIsCustomFileRenameCharacterSelected] = useState(fileRenameSeparator !== '' && fileRenameSeparator !== ' ');

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: false });
	
	if (!restrictions) {
		return (
			<div className="w-100 h-100 d-flex justify-content-center mt-5">
				<div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand kt-spinner--center" />
			</div>
		);
	}

	return (
		<div id={CONTAINER_ID}>
			<div className="px-20px bg-white rounded pb-20px">
				<div className="f-20px pt-20px text-dark">
					<FormattedMessage id="PROFILE.TITLE" />
				</div>

				<div className="mt-25px">
					<Formik
							initialValues={{
								...user
							}}
							validate={handleValidate}
							onSubmit={updateCompanyProfile}
						>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							setFieldValue,
						}) => (
							<form onSubmit={handleSubmit} noValidate autoComplete="off">
								<fieldset disabled={isInReadOnlyMode}>
									<div className="row">
										<Input
											labelId="AUTH.INPUT.FIRST_NAME"
											containerClass="col-md-4 col-12 mb-20px"
											variant="outlined"
											fullWidth
											name="firstName"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.firstName}
											helperText={touched.firstName && errors.firstName}
											error={Boolean(touched.firstName && errors.firstName)}
										/>

										<Input
											labelId="AUTH.INPUT.LAST_NAME"
											containerClass="col-md-4 col-12 mb-20px"
											variant="outlined"
											fullWidth
											name="lastName"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.lastName}
											helperText={touched.lastName && errors.lastName}
											error={Boolean(touched.lastName && errors.lastName)}
										/>

										<Input
											labelId="AUTH.INPUT.EMAIL"
											containerClass="col-md-4 col-12 mb-20px"
											variant="outlined"
											fullWidth
											disabled
											name="email"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.email}
											helperText={touched.email && errors.email}
											error={Boolean(touched.email && errors.email)}
										/>

										<div className="col-md-4 col-12">
											<div className="dark-custom-input input-phone form-group">
												<FormLabel className="mb-10">
													<FormattedMessage id="AUTH.INPUT.PHONE" />
												</FormLabel>
												<MuiPhoneNumber
													enableLongNumbers
													disableAreaCodes
													defaultCountry="au"
													variant="outlined"
													type="text"
													fullWidth
													name="phone"
													onBlur={handleBlur}
													onChange={(v) => setFieldValue(`phone`, v)}
													value={values.phone}
													helperText={touched.phone && errors.phone}
													error={Boolean(touched.phone && errors.phone)}
												/>
											</div>
										</div>

										<Input
											labelId="AUTH.INPUT.COMPANY_NAME"
											containerClass="col-md-4 col-12 mb-20px"
											variant="outlined"
											fullWidth
											name="company"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.company}
											helperText={touched.company && errors.company}
											error={Boolean(touched.company && errors.company)}
										/>

										<div className="col-md-4 col-12"></div>

										<div className="col-md-4 col-12">
											<div className="dark-input-label mb-2">
												<FormattedMessage id="AUTH.COMPANY_LOGO" />
											</div>
											<div className="d-flex justify-content-center justify-content-md-left">
												{
													logoUrl ? (
														<div
															className={
																clsx(
																	classes.logoContainer,
																	"flex-grow-1 d-flex flex-column align-items-center justify-content-center position-relative border",
																)
															}
														>
															<img
																alt="logo"
																src={logoUrl}
																className={clsx(classes.logoPreview, "h-100 w-100")}
															/>

															<div className={clsx("position-absolute d-flex", classes.logoActionsContainer)}>
																<div
																	className={clsx(classes.logoAction, "mr-2 d-flex justify-content-center align-items-center position-relative overflow-hidden")}
																>
																	<input
																		title={intl.formatMessage({ id: "CUSTOMIZATION.COMMUNICATION.BUSINESS_LOGO.UPLOAD" })}
																		type="file"
																		className="cursor-pointer"
																		accept="image/*"
																		style={{
																			position: 'absolute',
																			top: 0,
																			bottom: 0,
																			left: 0,
																			right: 0,
																			opacity: 0
																		}}
																		onChange={(e) => onDrop(e.target.files)}
																	/>

																	<div>
																		<span className="fas fa-upload"></span>
																	</div>
																</div>
																<div
																	className={clsx(classes.logoAction, "d-flex justify-content-center align-items-center")}
																	title={intl.formatMessage({ id: "CUSTOMIZATION.COMMUNICATION.BUSINESS_LOGO.DELETE" })}
																>
																	<div onClick={() => setLogoUrl("")}>
																		<span className="fas fa-trash-alt"></span>
																	</div>
																</div>
															</div>
														</div>
													) : (
														<div
															{...getRootProps()}
															className={
																clsx(
																	classes.logoContainer,
																	classes.emptyLogoContainer,
																	"flex-grow-1 d-flex flex-column align-items-center justify-content-center",
																	{ 'border-primary': isDragActive }
																)
															}
														>
															<div className="mb-25px">
																<img
																	alt="icon"
																	src={ImageIcon}
																	height="50"
																	width="50"
																/>
															</div>
															<div className={classes.emptyLogoMessage}>
																<FormattedMessage id="CUSTOMIZATION.COMMUNICATION.BUSINESS_LOGO.EMPTY_STATE.MESSAGE" />
															</div>

															<input {...getInputProps()} />
														</div>
													)
												}
											</div>
										</div>

										{
											!!flags.error[flagNames.USER_UPDATE] && (
												<Alert
													color="danger"
													className="text-center mb-25"
												>
													{flags.error[flagNames.USER_UPDATE]}
												</Alert>
											)
										}
									</div>

									<div className="pt-20px d-flex justify-content-md-start justify-content-center">
										<button
											type="submit"
											disabled={flags.loading[flagNames.USER_UPDATE]}
											className={`btn btn-primary btn-lg btn-elevate py-3 ${clsx(
												{
													"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": flags.loading[flagNames.USER_UPDATE]
												}
											)}`}
										>
											<FormattedMessage id="PROFILE.BUTTON" />
										</button>
									</div>
								</fieldset>
								{
									showCropModal && (
										<CropModal
											file={logo.value}
											titleLabelId={'CUSTOMIZATION.COMMUNICATION.BUSINESS_LOGO.CROP'}
											handleClose={(croppedImageUrl) => hideCropModal(croppedImageUrl)}
										/>
									)
								}

								<Prompt
									when={hasUnsavedChanges && !nextLocation.current}
									message={handleBlockedNavigation}
								/>

								{
									showUnsavedWarningModal && (
										<Confirm
											open
											icon={<WarningBlocksIcon />}
											variant="warning"
											handleClose={(shouldSave) => handleUnsavedModalClose(shouldSave, values)}
											title="CUSTOMIZATION.COMMUNICATIONS.UNSAVED_WARNING.TITLE"
											message="CUSTOMIZATION.COMMUNICATIONS.UNSAVED_WARNING.MESSAGE"
											submitButtonText="GENERAL.SAVE"
											cancelButtonText="GENERAL.DISCARD"
											loading={flags.loading[flagNames.USER_UPDATE]}
											error={flags.error[flagNames.USER_UPDATE]}
										/>
									)
								}
							</form>
						)}
					</Formik>
				</div>
			</div>

			<div className="p-20px mt-25px bg-white rounded">
				<div className="d-flex align-items-center">
					<div className="f-20px mr-15px text-dark">
						<FormattedMessage id="PROFILE.FIELD.CHAT_SERVICE" />
					</div>
					<div>
						<IOSSwitch
							rootClass={clsx("my-0 mr-2 ml-0", { "opacity-30": flags.loading[flagNames.UPDATE_CHAT_SERVICE] })}
							enabledTrackClass="bg-primary"
							checked={user.chatService}
							onChange={() => !flags.loading[flagNames.UPDATE_CHAT_SERVICE] && dispatch(updateChatService(!user.chatService))}
						/>
					</div>
				</div>
				<div className="mt-20px f-14px text-dark fw-300">
					<FormattedMessage id="PROFILE.FIELD.CHAT_SERVICE.HINT" />
				</div>
				{
					!!flags.error[flagNames.UPDATE_CHAT_SERVICE] && (
						<div className="mt-15px d-flex justify-content-center">
							<Alert
								color="danger"
								className="text-center mb-25"
							>
								{flags.error[flagNames.UPDATE_CHAT_SERVICE]}
							</Alert>
						</div>
					)
				}
			</div>

			<div className="p-20px mt-25px bg-white rounded">
				<div className="d-flex flex-column">
					<div className="f-20px text-dark mb-10px">
						<FormattedMessage id="PROFILE.FIELD.DOWNLOAD_FORMAT" />
					</div>
					<div>
						<RadioGroup
							name="downloadFormat"
							value={user.downloadFormat}
							onChange={(e) => {									
								const newValue = e.target.value;

								if (!flags.loading[flagNames.UPDATE_DOWNLOAD_FORMAT] && newValue !== user.downloadFormat) {
									dispatch(updateDownloadFormat(e.target.value));
								}
							}}
						>
							<FormControlLabel
								className="mb-0"
								disabled={flags.loading[flagNames.UPDATE_DOWNLOAD_FORMAT]}
								value="FLAT"
								control={<Radio color="primary" />}
								label={
									<div className="d-flex flex-column align-items-start flex-sm-row align-items-sm-center">
										<div className="mr-2 f-16px text-dark">
											<FormattedMessage id="PROFILE.FIELD.DOWNLOAD_FORMAT.FLAT.TITLE" />
										</div>
										<div className="text-dark f-14px fw-300">
											<FormattedMessage id="PROFILE.FIELD.DOWNLOAD_FORMAT.FLAT.SUBTITLE" />
										</div>
									</div>
								}
							/>
							<FormControlLabel
								className="mb-0"
								disabled={flags.loading[flagNames.UPDATE_DOWNLOAD_FORMAT]}
								value="HIERARCHICAL"
								control={<Radio color="primary" />}
								label={
									<div className="d-flex flex-column align-items-start flex-sm-row align-items-sm-center">
										<div className="mr-2 f-16px text-dark">
											<FormattedMessage id="PROFILE.FIELD.DOWNLOAD_FORMAT.HIERARCHICAL.TITLE" />
										</div>
										<div className="text-dark f-14px fw-300">
											<FormattedMessage id="PROFILE.FIELD.DOWNLOAD_FORMAT.HIERARCHICAL.SUBTITLE" />
										</div>
									</div>
								}
							/>
						</RadioGroup>
					</div>
				</div>
			</div>

			{
				process.env.REACT_APP_ENABLED_FILE_RENAME_SEPARATOR !== "false" && (
					<div
						className={clsx("p-20px mt-25px bg-white rounded", { "border-primary-blink": goTo === "file-rename-separator-setting" })}
						id="file-rename-separator-setting"
					>
						<div className="d-flex flex-column">
							<div className="f-20px text-dark mb-15px">
								<FormattedMessage id="PROFILE.FIELD.FILE_RENAME_SEPARATOR" />
							</div>
							<div>
								<div className="d-flex">
									<div className="mr-10px">
										<FormControlLabel
											className="mb-0 mr-0"
											onClick={() => setIsCustomFileRenameCharacterSelected(true)}
											disabled={flags.loading[flagNames.UPDATE_FILE_RENAME_SEPARATOR]}
											control={<Radio color="primary" checked={isCustomFileRenameCharacterSelected} />}
											classes={{
												label: "f-16px text-dark",
											}}
											label={intl.formatMessage({ id: "PROFILE.FIELD.FILE_RENAME_SEPARATOR.SPECIFIC_CHARACTER.TITLE" })}
										/>
									</div>

									<div>
										<Input
											type="text"
											className={classes.customCharacterInput}
											variant="outlined"
											onFocus={(e) => e.target.select()}
											onChange={(e) => e.target.value = e.target.value.substring(e.target.value.length - 1)}
											onBlur={(e) => e.target.value && handleFileRenameSeparatorChange(e.target.value)}
											disabled={flags.loading[flagNames.UPDATE_FILE_RENAME_SEPARATOR] || !isCustomFileRenameCharacterSelected}
											defaultValue={fileRenameSeparator}
										/>
									</div>
								</div>

								<div>
									<FormControlLabel
										className="mb-0"
										disabled={flags.loading[flagNames.UPDATE_FILE_RENAME_SEPARATOR]}
										onClick={() => handleFileRenameSeparatorChange(' ')}
										control={<Radio color="primary" checked={!isCustomFileRenameCharacterSelected && fileRenameSeparator === ' '} />}
										label={intl.formatMessage({ id: "PROFILE.FIELD.FILE_RENAME_SEPARATOR.SPACE.TITLE" })}
										classes={{
											label: "f-16px text-dark",
										}}
									/>
								</div>

								<div>
									<FormControlLabel
										className="mb-0"
										disabled={flags.loading[flagNames.UPDATE_FILE_RENAME_SEPARATOR]}
										onClick={() => handleFileRenameSeparatorChange('')}
										control={<Radio color="primary" checked={!isCustomFileRenameCharacterSelected && fileRenameSeparator === ''} />}
										label={intl.formatMessage({ id: "PROFILE.FIELD.FILE_RENAME_SEPARATOR.NOTHING.TITLE" })}
										classes={{
											label: "f-16px text-dark",
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				)
			}

			<div className="p-20px mt-25px bg-white rounded">
				<div className="f-20px text-dark mb-10px">
					<FormattedMessage id="CHANGE_PASSWORD.TITLE" />
				</div>

				<div className="mt-25px">
					<Formik
						initialValues={{
							oldPassword: '',
							newPassword: '',
							confirmNewPassword: '',
						}}
						validate={values => {
							const errors = {};

							if (!values.oldPassword) {
								errors.oldPassword = intl.formatMessage({
									id: "AUTH.VALIDATION.REQUIRED_FIELD"
								});
							}
							if (!values.newPassword) {
								errors.newPassword = intl.formatMessage({
									id: "AUTH.VALIDATION.REQUIRED_FIELD"
								});
							}
							if (!values.confirmNewPassword) {
								errors.confirmNewPassword = intl.formatMessage({
									id: "AUTH.VALIDATION.REQUIRED_FIELD"
								});
							}
							if (values.confirmNewPassword !== values.newPassword) {
								errors.confirmNewPassword = intl.formatMessage({
									id: "CHANGE_PASSWORD.VALIDATION.PASSWORD_MISMATCH"
								});
							}

							console.log(errors);

							return errors;
						}}
						onSubmit={(values) => {
							dispatch(updatePassword(
								values.oldPassword,
								values.newPassword
							));
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							setFieldValue,
						}) => (
							<form onSubmit={handleSubmit} noValidate autoComplete="off" className="row">
								<Input
									labelId="CHANGE_PASSWORD.INPUT.OLD_PASSWORD"
									containerClass="col-md-4 col-12 mb-20px"
									type="password"
									variant="outlined"
									fullWidth
									name="oldPassword"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.oldPassword}
									helperText={touched.oldPassword && errors.oldPassword}
									error={Boolean(touched.oldPassword && errors.oldPassword)}
								/>

								<Input
									labelId="CHANGE_PASSWORD.INPUT.NEW_PASSWORD"
									containerClass="col-md-4 col-12 mb-20px"
									type="password"
									variant="outlined"
									fullWidth
									name="newPassword"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.newPassword}
									helperText={touched.newPassword && errors.newPassword}
									error={Boolean(touched.newPassword && errors.newPassword)}
								/>

								<Input
									labelId="CHANGE_PASSWORD.INPUT.CONFIRM_NEW_PASSWORD"
									containerClass="col-md-4 col-12 mb-20px"
									type="password"
									variant="outlined"
									fullWidth
									name="confirmNewPassword"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.confirmNewPassword}
									helperText={touched.confirmNewPassword && errors.confirmNewPassword}
									error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
								/>

								{
									!!flags.error[flagNames.UPDATE_PASSWORD] && (
										<div className="col-12 d-flex justify-content-center py-15px">
											<Alert
												color="danger"
												className="text-center mb-25"
											>
												{flags.error[flagNames.UPDATE_PASSWORD]}
											</Alert>
										</div>
									)
								}

								<div className="d-flex justify-content-md-start justify-content-center col-12">
									<button
										type="submit"
										disabled={flags.loading[flagNames.UPDATE_PASSWORD]}
										className={`btn btn-primary btn-lg btn-elevate py-3 ${clsx(
											{
												"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": flags.loading[flagNames.UPDATE_PASSWORD]
											}
										)}`}
									>
										<FormattedMessage id="CHANGE_PASSWORD.BUTTON" />
									</button>
								</div>
							</form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}

export default Profile;
