import React from "react";
import { TextField, FormLabel } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const Input = (allProps) => {
	const {
		labelId,
		labelClass,
		containerClass = '',
		startAdornment,
		endAdornment,
		InputProps = {},
		...props
	} = allProps;
	const parentClass = startAdornment ? "dark-custom-input-with-start-adornment" : "dark-custom-input";
	const finalInputProps = {
		...InputProps,
		startAdornment,
		endAdornment,
	};

	return (
		<div className={`${parentClass} form-group mb-0 ${containerClass}`}>
			{
				!!labelId && (
					<FormLabel className={`mb-10px ${labelClass}`}>
						<FormattedMessage id={labelId} />
					</FormLabel>
				)
			}
			<TextField
				{...props}
				InputProps={finalInputProps}
				autoComplete="new-password"
			/>
		</div>
	)
}

export default Input;